import React from "react";
import { Typography } from "antd";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import Title from "antd/es/typography/Title";

import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

const { Text } = Typography;
export const Code = (token, serviceToken, style) => (
  <Editor
    value={`const wooffer = require("wooffer");
const express = require("express");

wooffer("${token}", "${serviceToken}");

// Add this code to track endpoint usage(Optional)
const app = express();
app.use(wooffer.requestMonitoring);`}
    padding={10}
    onValueChange={() => { }}
    highlight={(code) => highlight(code, languages.js)}
    style={style}
  />
);

export const NestCode = (token, serviceToken, style) => (
  <Editor
    value={`import { NestFactory } from '@nestjs/core';
import { AppModule } from './app.module';
import { ValidationPipe } from '@nestjs/common';

const wooffer = require('wooffer');

wooffer("${token}", "${serviceToken}");

async function bootstrap() {
  const app = await NestFactory.create(AppModule);

  app.useGlobalPipes(
    wooffer.requestMonitoring,
    new ValidationPipe({
      whitelist: true,
    }),
  );

  await app.listen(process.env.PORT ?? 3000);
}
bootstrap();`}
    padding={10}
    onValueChange={() => { }}
    highlight={(code) => highlight(code, languages.js)}
    style={style}
  />
);

export const FastifyCode = (token, serviceToken, style) => (
  <Editor
    value={`import wooffer from "wooffer";

wooffer(
    "${token}",
    "${serviceToken}",
);

fastify.addHook("onRequest", async (request, reply) => {
    return new Promise((resolve, reject) => {
        wooffer.requestMonitoring(request, reply.raw, (err) => {
            if (err) {
                reject(err);
            }
            resolve();
        });
    });
});`}
    padding={10}
    onValueChange={() => { }}
    highlight={(code) => highlight(code, languages.js)}
    style={style}
  />
);

export const EnvCode = (token, serviceToken, style) => (
  <Editor
    value={`woofferToken = ${token}
woofferServiceToken = ${serviceToken}`}
    padding={10}
    onValueChange={() => { }}
    highlight={(code) => highlight(code, languages.js)}
    style={style}
  />
);

export const NestMiddlewarCode = (style) => (
  <Editor
    value={`import { requestMonitoring } from 'wooffer';

export class RequestMonitoringMiddleware {
  use(req, res, next) {
    requestMonitoring(req, res, next);
  }
}`}
    padding={10}
    onValueChange={() => { }}
    highlight={(code) => highlight(code, languages.js)}
    style={style}
  />
);

export const NestAppModuleCode = (style) => (
  <Editor
    value={`import { MiddlewareConsumer, Module, NestModule } from '@nestjs/common';
import { RequestMonitoringMiddleware } from './wooffer.wrapper';

@Module({
  imports: [],
})
export class AppModule implements NestModule {
  configure(consumer: MiddlewareConsumer) {
    consumer.apply(RequestMonitoringMiddleware).forRoutes('*');
  }
}`}
    padding={10}
    onValueChange={() => { }}
    highlight={(code) => highlight(code, languages.js)}
    style={style}
  />
);

export const LogCode = (style) => (
  <Editor
    value={`const wooffer = require("wooffer");

// For Event success Log
wooffer.success("EventName: Login \\nUsername:Jhon Due");

// For Event alert Log
wooffer.alert("EventName: User visit \\nUsername:Jhon Due");

// For Event fail Log
wooffer.fail("EventName: Payment Fail \\nUsername:Jhon Due");`}
    padding={10}
    onValueChange={() => { }}
    highlight={(code) => highlight(code, languages.js)}
    style={style}
  />
);

export const APICode = (style) => (
  <Editor
    value={`const wooffer = require("wooffer");

// By Config Variable    
const config = {
  method: "post",
  maxBodyLength: Infinity,
  url: <Your URL>,
  headers: {
    "Content-Type": "application/json",
    "<Your Headers>"
  },
  data: {
    "<Your Payload>"
  },
};
    
const response = await wooffer.axios.request(config);
`}
    padding={10}
    onValueChange={() => { }}
    highlight={(code) => highlight(code, languages.js)}
    style={style}
  />
);

export const APIByMethodCode = (style) => (
  <Editor
    value={`const wooffer = require("wooffer");

const response = await wooffer.axios.get("<Your URL>");

const response = await wooffer.axios.post("<Your URL>","<Your Payload>");

const response = await wooffer.axios.patch("<Your URL>","<Your Payload>");

const response = await wooffer.axios.put("<Your URL>","<Your Payload>");

const response = await wooffer.axios.delete("<Your URL>");
`}
    padding={10}
    onValueChange={() => { }}
    highlight={(code) => highlight(code, languages.js)}
    style={style}
  />
);

const AddService = ({ environmentID, id }) => {
  const SpaceGap = <div className="mt-5" />;
  return (
    <div className="px-10 pt-5">
      <Title level={5}>Install Dependency</Title>
      <Text copyable code>
        npm i wooffer
      </Text>
      {SpaceGap}
      <Title level={5}>Add this code into your env file</Title>
      {EnvCode(id, environmentID, {
        fontFamily: "monospace",
        fontSize: 17,
        border: "1px solid #d6d4d4",
        borderRadius: "8px",
        margin: "0px 30px",
        background: "white",
      })}
      {SpaceGap}
      <Title level={5}>
        Add this code into your root file app.js or index.js
      </Title>
      {Code(id, environmentID, {
        fontFamily: "monospace",
        fontSize: 17,
        border: "1px solid #d6d4d4",
        borderRadius: "8px",
        margin: "0px 30px",
        background: "white",
      })}
      {SpaceGap}
    </div>
  );
};

export default AddService;
