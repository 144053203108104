/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Spin,
  Steps,
  Timeline,
  Typography,
  theme,
} from "antd";
import Title from "antd/es/typography/Title";
import { SmileOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import useHttp from "../../../../hooks/use-http";
import Identifier from "./steps/Identifer";
import AddService, {
  // APIByMethodCode,
  // APICode,
  Code,

  FastifyCode,

  // EnvCode,
  LogCode,
  NestAppModuleCode,
  NestCode,
  NestMiddlewarCode,
} from "./steps/add-service";
import Done from "./steps/done";
import { apiGenerator, getServiceRoute } from "../../../../util/functions";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";

import { getAuthToken } from "../../../../util/API/authStorage";

const { Text } = Typography;
const ServiceSetup = (props) => {
  const {
    projectID, serviceID, environmentID, tour,
  } = useParams();
  const navigate = useNavigate();
  const [mode, setMode] = useState("express");
  const [serviceDetails, setServiceDetails] = useState({});
  const [channelId, setChannelId] = useState(null);
  const [current, setCurrent] = useState(0);
  const [editData, setEditData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { token } = theme.useToken();
  const API = useHttp();

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const steps = [
    {
      title: "Create Identifier",
      content: (
        <Identifier
          serviceDetails={serviceDetails}
          channelId={channelId}
          setServiceDetails={setServiceDetails}
          setChannelId={setChannelId}
          serviceID={serviceID}
        />
      ),
    },

    {
      title: "Add Monitoring Service",
      content: (
        <AddService
          id={props?.user_details?.id}
          environmentID={environmentID}
        />
      ),
    },
    {
      title: "Done",
      content: <Done />,
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const updateService = (payload) => {
    if (payload?.slack == null) delete payload?.slack;
    API.sendRequest(
      apiGenerator(CONSTANTS.API.service.update, {
        dataId: serviceID,
      }),
      (res) => {
        if (res?.status === "success") {
          props?.socket?.emit("updateServiceEnvironmentInformation", {
            serviceToken: environmentID,
            token: props?.user_details?.id,

          });
          setEditData(null);
          if (serviceDetails?.slack) setRefresh((prevs) => !prevs);
          else {
            navigate(
              `${getServiceRoute({
                projectId: projectID,
                serviceID: serviceDetails?.id,
                environmentID,
              })}/log`,
            );
          }
        }
      },
      payload,
      "Service Update Successfully !!!",
    );
  };

  const SpaceGap = <div className="mt-5" />;

  const editDataHandler = (value) => {
    updateService(value, "Updated");
  };

  const contentStyle = {
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 25,
  };

  useEffect(() => {
    if (getAuthToken()) {
      API.sendRequest(
        apiGenerator(CONSTANTS.API.service.getOne, {
          dataId: serviceID,
        }),
        (res) => {
          if (res.data == null) return navigate("/unAuthorize");
          if (res?.status === "success") {
            setServiceDetails(res?.data);
          }
        },
      );
    }
  }, [refresh, environmentID, serviceID]);

  return (
    <>
      <div className="flex gap-4 mb-2">
        <Radio.Group onChange={handleModeChange} value={mode} className="mr-3">
          <Radio.Button value="express">Express </Radio.Button>
          <Radio.Button value="nest">Nest </Radio.Button>
          <Radio.Button value="fastify">Fastify </Radio.Button>
        </Radio.Group>
      </div>
      <Card>
        {mode === 'express' ? (
          <>
            {API.isLoading ? (
              <div className="h-[120rem]  w-full">
                <Spin className="mt-48" tip="Loading" size="large">
                  <div className="content" />
                </Spin>
              </div>
            ) : (
              <div>
                {!tour ? (
                  <div className="px50 ">
                    <Timeline
                      className="ps-5 pt-5"
                      items={[
                        {
                          children: (
                            <>
                              <Title level={5}>Step 1. Create Identifier</Title>
                              {"name" in serviceDetails && (
                                <Form
                                  initialValues={{
                                    name: serviceDetails?.name || "",
                                    slack: serviceDetails?.slack || "",
                                  }}
                                >
                                  <Row>
                                    <Col span={18}>
                                      <Form.Item
                                        name="name"
                                        className="mt-3 mb-2"
                                      >
                                        <Input
                                          initialvalues={
                                            serviceDetails?.name || ""
                                          }
                                          placeholder="Enter Your Service Name you want to track"
                                          disabled
                                          onChange={() => { }}
                                          prefix={`Service Name    | `}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col>
                                      <Button
                                        onClick={() => {
                                          setEditData(serviceDetails);
                                        }}
                                        className="mt-3 ml-5 px-6 textcolor"
                                        type="primary"
                                      >
                                        Edit
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={18}>
                                      <Form.Item
                                        name="slack"
                                        className="mt-1 mb-0"
                                      >
                                        <Input
                                          name="slack"
                                          onChange={() => { }}
                                          disabled
                                          placeholder="Enter Your Slack Webhook"
                                          prefix="Slack Webhook | "
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <ModalFormCreator
                                    open={editData !== null}
                                    onCreate={editDataHandler}
                                    onCancel={() => {
                                      setEditData(null);
                                    }}
                                    name="Edit Identifier"
                                    menu="EDIT_SERVICE_MODAL"
                                    formData={{ ...editData }}
                                  />
                                </Form>
                              )}
                              <Text type="secondary" className="mt-2">
                                Don&apos;t know about Slack Webhook ?
                                {" "}
                                <a
                                  href="https://www.youtube.com/watch?v=sxtC40gUS2A"
                                  target="_blank"
                                  rel="noreferrer"
                                  className="defaultColor"
                                >
                                  Click Here
                                </a>
                              </Text>
                              {SpaceGap}
                            </>
                          ),
                        },
                        {
                          children: (
                            <>
                              <Title level={5}>Step 2. Install Dependency</Title>
                              <Text className="" copyable code>
                                npm i wooffer
                              </Text>
                              {SpaceGap}
                            </>
                          ),
                        },

                        // {
                        //   children: (
                        //     <>
                        //       <Title level={5}>
                        //         Step 3. Add this code into your env file
                        //       </Title>
                        //       {EnvCode(props?.user_details?.id, environmentID, {
                        //         fontFamily: "monospace",
                        //         fontSize: 17,
                        //         border: "1px solid #d6d4d4",
                        //         borderRadius: "8px",
                        //         width: "700px ",
                        //         background: "white",
                        //         outLine: "0px",
                        //       })}
                        //       Note: The above code should be used only once in the
                        //       code
                        //       {SpaceGap}
                        //     </>
                        //   ),
                        // },

                        {
                          children: (
                            <>
                              <Title level={5}>
                                Step 3. Add this code into your root file app.js
                                or index.js
                              </Title>
                              {Code(props?.user_details?.id, environmentID, {
                                fontFamily: "monospace",
                                fontSize: 17,
                                border: "1px solid #d6d4d4",
                                borderRadius: "8px",
                                maxWidth: "900px ",
                                background: "white",
                                outLine: "0px",
                              })}
                              Note: The above code should be used only once in the
                              code
                              {SpaceGap}
                            </>
                          ),
                        },

                        {
                          children: (
                            <>
                              <Title level={5}>
                                Step 4 (Optional). Use below Code to get Custom
                                Log
                              </Title>
                              {LogCode({
                                fontFamily: "monospace",
                                fontSize: 17,
                                border: "1px solid #d6d4d4",
                                borderRadius: "8px",
                                width: "700px ",
                                background: "white",
                                outLine: "0px",
                              })}

                              {SpaceGap}
                            </>
                          ),
                        },

                        // {
                        //   children: (
                        //     <>
                        //       <Title level={5}>
                        //         Step 5 (Optional). Use below Code to track
                        //         third-party APIs
                        //       </Title>
                        //       {APICode({
                        //         fontFamily: "monospace",
                        //         fontSize: 17,
                        //         border: "1px solid #d6d4d4",
                        //         borderRadius: "8px",
                        //         width: "800px ",
                        //         background: "white",
                        //         outLine: "0px",
                        //       })}

                        //       {SpaceGap}
                        //       <Text>OR By Method</Text>
                        //       {SpaceGap}
                        //       {APIByMethodCode({
                        //         fontFamily: "monospace",
                        //         fontSize: 17,
                        //         border: "1px solid #d6d4d4",
                        //         borderRadius: "8px",
                        //         width: "800px ",
                        //         background: "white",
                        //         outLine: "0px",
                        //       })}

                        //       {SpaceGap}
                        //     </>
                        //   ),
                        // },

                        {
                          color: "green",
                          dot: <SmileOutlined />,
                          children: (
                            <Title type="success" level={5}>
                              Rest assured, Wooffer is your steadfast guardian,
                              promptly alerting you on Slack to any instances of
                              system crashes or code errors. Your business&apos;s
                              safety is our top priority, and our continuous watch
                              ensures that you stay informed and empowered in all
                              circumstances.
                            </Title>
                          ),
                        },
                      ]}
                    />
                  </div>
                ) : (
                  <>
                    <Steps
                      current={current}
                      items={items}
                      className="mt-4 px-32"
                    />
                    <div className="mx-10" style={contentStyle}>
                      {steps[current].content}
                    </div>

                    <div
                      style={{
                        marginTop: 24,
                      }}
                      className="flex justify-center"
                    >
                      {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                          Next
                        </Button>
                      )}
                      {current === steps.length - 1 && (
                        <Button
                          type="primary"
                          className="textcolor"
                          onClick={() => updateService({
                            name: serviceDetails?.name,
                            slack: channelId,
                          })}
                        >
                          Done
                        </Button>
                      )}
                      {current > 0 && (
                        <Button
                          style={{
                            margin: "0 8px",
                          }}
                          onClick={() => prev()}
                        >
                          Previous
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        ) : mode === 'nest' ? (
          <>
            {API.isLoading ? (
              <div className="h-[120rem]  w-full">
                <Spin className="mt-48" tip="Loading" size="large">
                  <div className="content" />
                </Spin>
              </div>
            ) : (
              <div>
                {!tour ? (
                  <div className="px50 ">
                    <Timeline
                      className="ps-5 pt-5"
                      items={[
                        {
                          children: (
                            <>
                              <Title level={5}>Step 1. Create Identifier</Title>
                              {"name" in serviceDetails && (
                                <Form
                                  initialValues={{
                                    name: serviceDetails?.name || "",
                                    slack: serviceDetails?.slack || "",
                                  }}
                                >
                                  <Row>
                                    <Col span={18}>
                                      <Form.Item
                                        name="name"
                                        className="mt-3 mb-2"
                                      >
                                        <Input
                                          initialvalues={
                                            serviceDetails?.name || ""
                                          }
                                          placeholder="Enter Your Service Name you want to track"
                                          disabled
                                          onChange={() => { }}
                                          prefix={`Service Name    | `}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col>
                                      <Button
                                        onClick={() => {
                                          setEditData(serviceDetails);
                                        }}
                                        className="mt-3 ml-5 px-6 textcolor"
                                        type="primary"
                                      >
                                        Edit
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={18}>
                                      <Form.Item
                                        name="slack"
                                        className="mt-1 mb-0"
                                      >
                                        <Input
                                          name="slack"
                                          onChange={() => { }}
                                          disabled
                                          placeholder="Enter Your Slack Webhook"
                                          prefix="Slack Webhook | "
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <ModalFormCreator
                                    open={editData !== null}
                                    onCreate={editDataHandler}
                                    onCancel={() => {
                                      setEditData(null);
                                    }}
                                    name="Edit Identifier"
                                    menu="EDIT_SERVICE_MODAL"
                                    formData={{ ...editData }}
                                  />
                                </Form>
                              )}
                              <Text type="secondary" className="mt-2">
                                Don&apos;t know about Slack Webhook ?
                                {" "}
                                <a
                                  href="https://www.youtube.com/watch?v=sxtC40gUS2A"
                                  target="_blank"
                                  rel="noreferrer"
                                  className="defaultColor"
                                >
                                  Click Here
                                </a>
                              </Text>
                              {SpaceGap}
                            </>
                          ),
                        },
                        {
                          children: (
                            <>
                              <Title level={5}>Step 2. Install Dependency</Title>
                              <Text className="" copyable code>
                                npm i wooffer
                              </Text>
                              {SpaceGap}
                            </>
                          ),
                        },

                        // {
                        //   children: (
                        //     <>
                        //       <Title level={5}>
                        //         Step 3. Add this code into your env file
                        //       </Title>
                        //       {EnvCode(props?.user_details?.id, environmentID, {
                        //         fontFamily: "monospace",
                        //         fontSize: 17,
                        //         border: "1px solid #d6d4d4",
                        //         borderRadius: "8px",
                        //         width: "700px ",
                        //         background: "white",
                        //         outLine: "0px",
                        //       })}
                        //       Note: The above code should be used only once in the
                        //       code
                        //       {SpaceGap}
                        //     </>
                        //   ),
                        // },

                        {
                          children: (
                            <>
                              <Title level={5}>
                                Step 3. Add this code into your root file main.ts
                                or app.ts
                              </Title>
                              {NestCode(props?.user_details?.id, environmentID, {
                                fontFamily: "monospace",
                                fontSize: 17,
                                border: "1px solid #d6d4d4",
                                borderRadius: "8px",
                                maxWidth: "900px ",
                                background: "white",
                                outLine: "0px",
                              })}
                              Note: The above code should be used only once in the
                              code
                              {SpaceGap}
                            </>
                          ),
                        },

                        {
                          children: (
                            <>
                              <Title level={5}>
                                Step 4 (Optional). Create this wrapper file for API monitoring
                              </Title>
                              {NestMiddlewarCode({
                                fontFamily: "monospace",
                                fontSize: 17,
                                border: "1px solid #d6d4d4",
                                borderRadius: "8px",
                                width: "700px ",
                                background: "white",
                                outLine: "0px",
                              })}

                              {SpaceGap}
                            </>
                          ),
                        },
                        {
                          children: (
                            <>
                              <Title level={5}>
                                Step 5 (Optional). Add wrapper file in app.module.ts
                              </Title>
                              {NestAppModuleCode({
                                fontFamily: "monospace",
                                fontSize: 17,
                                border: "1px solid #d6d4d4",
                                borderRadius: "8px",
                                width: "700px ",
                                background: "white",
                                outLine: "0px",
                              })}

                              {SpaceGap}
                            </>
                          ),
                        },
                        {
                          children: (
                            <>
                              <Title level={5}>
                                Step 6 (Optional). Use below Code to get Custom
                                Log
                              </Title>
                              {LogCode({
                                fontFamily: "monospace",
                                fontSize: 17,
                                border: "1px solid #d6d4d4",
                                borderRadius: "8px",
                                width: "700px ",
                                background: "white",
                                outLine: "0px",
                              })}

                              {SpaceGap}
                            </>
                          ),
                        },

                        // {
                        //   children: (
                        //     <>
                        //       <Title level={5}>
                        //         Step 5 (Optional). Use below Code to track
                        //         third-party APIs
                        //       </Title>
                        //       {APICode({
                        //         fontFamily: "monospace",
                        //         fontSize: 17,
                        //         border: "1px solid #d6d4d4",
                        //         borderRadius: "8px",
                        //         width: "800px ",
                        //         background: "white",
                        //         outLine: "0px",
                        //       })}

                        //       {SpaceGap}
                        //       <Text>OR By Method</Text>
                        //       {SpaceGap}
                        //       {APIByMethodCode({
                        //         fontFamily: "monospace",
                        //         fontSize: 17,
                        //         border: "1px solid #d6d4d4",
                        //         borderRadius: "8px",
                        //         width: "800px ",
                        //         background: "white",
                        //         outLine: "0px",
                        //       })}

                        //       {SpaceGap}
                        //     </>
                        //   ),
                        // },

                        {
                          color: "green",
                          dot: <SmileOutlined />,
                          children: (
                            <Title type="success" level={5}>
                              Rest assured, Wooffer is your steadfast guardian,
                              promptly alerting you on Slack to any instances of
                              system crashes or code errors. Your business&apos;s
                              safety is our top priority, and our continuous watch
                              ensures that you stay informed and empowered in all
                              circumstances.
                            </Title>
                          ),
                        },
                      ]}
                    />
                  </div>
                ) : (
                  <>
                    <Steps
                      current={current}
                      items={items}
                      className="mt-4 px-32"
                    />
                    <div className="mx-10" style={contentStyle}>
                      {steps[current].content}
                    </div>

                    <div
                      style={{
                        marginTop: 24,
                      }}
                      className="flex justify-center"
                    >
                      {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                          Next
                        </Button>
                      )}
                      {current === steps.length - 1 && (
                        <Button
                          type="primary"
                          className="textcolor"
                          onClick={() => updateService({
                            name: serviceDetails?.name,
                            slack: channelId,
                          })}
                        >
                          Done
                        </Button>
                      )}
                      {current > 0 && (
                        <Button
                          style={{
                            margin: "0 8px",
                          }}
                          onClick={() => prev()}
                        >
                          Previous
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        ) : mode === 'fastify' ? (
          <>
            {API.isLoading ? (
              <div className="h-[120rem]  w-full">
                <Spin className="mt-48" tip="Loading" size="large">
                  <div className="content" />
                </Spin>
              </div>
            ) : (
              <div>
                {!tour ? (
                  <div className="px50 ">
                    <Timeline
                      className="ps-5 pt-5"
                      items={[
                        {
                          children: (
                            <>
                              <Title level={5}>Step 1. Create Identifier</Title>
                              {"name" in serviceDetails && (
                                <Form
                                  initialValues={{
                                    name: serviceDetails?.name || "",
                                    slack: serviceDetails?.slack || "",
                                  }}
                                >
                                  <Row>
                                    <Col span={18}>
                                      <Form.Item
                                        name="name"
                                        className="mt-3 mb-2"
                                      >
                                        <Input
                                          initialvalues={
                                            serviceDetails?.name || ""
                                          }
                                          placeholder="Enter Your Service Name you want to track"
                                          disabled
                                          onChange={() => { }}
                                          prefix={`Service Name    | `}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col>
                                      <Button
                                        onClick={() => {
                                          setEditData(serviceDetails);
                                        }}
                                        className="mt-3 ml-5 px-6 textcolor"
                                        type="primary"
                                      >
                                        Edit
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={18}>
                                      <Form.Item
                                        name="slack"
                                        className="mt-1 mb-0"
                                      >
                                        <Input
                                          name="slack"
                                          onChange={() => { }}
                                          disabled
                                          placeholder="Enter Your Slack Webhook"
                                          prefix="Slack Webhook | "
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <ModalFormCreator
                                    open={editData !== null}
                                    onCreate={editDataHandler}
                                    onCancel={() => {
                                      setEditData(null);
                                    }}
                                    name="Edit Identifier"
                                    menu="EDIT_SERVICE_MODAL"
                                    formData={{ ...editData }}
                                  />
                                </Form>
                              )}
                              <Text type="secondary" className="mt-2">
                                Don&apos;t know about Slack Webhook ?
                                {" "}
                                <a
                                  href="https://www.youtube.com/watch?v=sxtC40gUS2A"
                                  target="_blank"
                                  rel="noreferrer"
                                  className="defaultColor"
                                >
                                  Click Here
                                </a>
                              </Text>
                              {SpaceGap}
                            </>
                          ),
                        },
                        {
                          children: (
                            <>
                              <Title level={5}>Step 2. Install Dependency</Title>
                              <Text className="" copyable code>
                                npm i wooffer
                              </Text>
                              {SpaceGap}
                            </>
                          ),
                        },

                        // {
                        //   children: (
                        //     <>
                        //       <Title level={5}>
                        //         Step 3. Add this code into your env file
                        //       </Title>
                        //       {EnvCode(props?.user_details?.id, environmentID, {
                        //         fontFamily: "monospace",
                        //         fontSize: 17,
                        //         border: "1px solid #d6d4d4",
                        //         borderRadius: "8px",
                        //         width: "700px ",
                        //         background: "white",
                        //         outLine: "0px",
                        //       })}
                        //       Note: The above code should be used only once in the
                        //       code
                        //       {SpaceGap}
                        //     </>
                        //   ),
                        // },

                        {
                          children: (
                            <>
                              <Title level={5}>
                                Step 3. Add this code into your root file app.js or index.js
                              </Title>
                              {FastifyCode(props?.user_details?.id, environmentID, {
                                fontFamily: "monospace",
                                fontSize: 17,
                                border: "1px solid #d6d4d4",
                                borderRadius: "8px",
                                maxWidth: "900px ",
                                background: "white",
                                outLine: "0px",
                              })}
                              Note: The above code should be used only once in the
                              code
                              {SpaceGap}
                            </>
                          ),
                        },

                        {
                          children: (
                            <>
                              <Title level={5}>
                                Step 4 (Optional). Use below Code to get Custom Log
                              </Title>
                              {LogCode({
                                fontFamily: "monospace",
                                fontSize: 17,
                                border: "1px solid #d6d4d4",
                                borderRadius: "8px",
                                width: "700px ",
                                background: "white",
                                outLine: "0px",
                              })}

                              {SpaceGap}
                            </>
                          ),
                        },
                        // {
                        //   children: (
                        //     <>
                        //       <Title level={5}>
                        //         Step 5 (Optional). Add wrapper file in app.module.ts
                        //       </Title>
                        //       {NestAppModuleCode({
                        //         fontFamily: "monospace",
                        //         fontSize: 17,
                        //         border: "1px solid #d6d4d4",
                        //         borderRadius: "8px",
                        //         width: "700px ",
                        //         background: "white",
                        //         outLine: "0px",
                        //       })}

                        //       {SpaceGap}
                        //     </>
                        //   ),
                        // },
                        // {
                        //   children: (
                        //     <>
                        //       <Title level={5}>
                        //         Step 6 (Optional). Use below Code to get Custom
                        //         Log
                        //       </Title>
                        //       {LogCode({
                        //         fontFamily: "monospace",
                        //         fontSize: 17,
                        //         border: "1px solid #d6d4d4",
                        //         borderRadius: "8px",
                        //         width: "700px ",
                        //         background: "white",
                        //         outLine: "0px",
                        //       })}

                        //       {SpaceGap}
                        //     </>
                        //   ),
                        // },

                        // {
                        //   children: (
                        //     <>
                        //       <Title level={5}>
                        //         Step 5 (Optional). Use below Code to track
                        //         third-party APIs
                        //       </Title>
                        //       {APICode({
                        //         fontFamily: "monospace",
                        //         fontSize: 17,
                        //         border: "1px solid #d6d4d4",
                        //         borderRadius: "8px",
                        //         width: "800px ",
                        //         background: "white",
                        //         outLine: "0px",
                        //       })}

                        //       {SpaceGap}
                        //       <Text>OR By Method</Text>
                        //       {SpaceGap}
                        //       {APIByMethodCode({
                        //         fontFamily: "monospace",
                        //         fontSize: 17,
                        //         border: "1px solid #d6d4d4",
                        //         borderRadius: "8px",
                        //         width: "800px ",
                        //         background: "white",
                        //         outLine: "0px",
                        //       })}

                        //       {SpaceGap}
                        //     </>
                        //   ),
                        // },

                        {
                          color: "green",
                          dot: <SmileOutlined />,
                          children: (
                            <Title type="success" level={5}>
                              Rest assured, Wooffer is your steadfast guardian,
                              promptly alerting you on Slack to any instances of
                              system crashes or code errors. Your business&apos;s
                              safety is our top priority, and our continuous watch
                              ensures that you stay informed and empowered in all
                              circumstances.
                            </Title>
                          ),
                        },
                      ]}
                    />
                  </div>
                ) : (
                  <>
                    <Steps
                      current={current}
                      items={items}
                      className="mt-4 px-32"
                    />
                    <div className="mx-10" style={contentStyle}>
                      {steps[current].content}
                    </div>

                    <div
                      style={{
                        marginTop: 24,
                      }}
                      className="flex justify-center"
                    >
                      {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                          Next
                        </Button>
                      )}
                      {current === steps.length - 1 && (
                        <Button
                          type="primary"
                          className="textcolor"
                          onClick={() => updateService({
                            name: serviceDetails?.name,
                            slack: channelId,
                          })}
                        >
                          Done
                        </Button>
                      )}
                      {current > 0 && (
                        <Button
                          style={{
                            margin: "0 8px",
                          }}
                          onClick={() => prev()}
                        >
                          Previous
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        ) : null}
      </Card>
    </>
  );
};

export default ServiceSetup;
